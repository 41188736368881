import { render, staticRenderFns } from "./HdxdEdit.vue?vue&type=template&id=437c672a&scoped=true"
import script from "./HdxdEdit.vue?vue&type=script&lang=js"
export * from "./HdxdEdit.vue?vue&type=script&lang=js"
import style0 from "./HdxdEdit.vue?vue&type=style&index=0&id=437c672a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437c672a",
  null
  
)

export default component.exports